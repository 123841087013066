import React, {useEffect} from 'react';
import './styles.scss';
import {requestFallbackPortalLink, requestPortalLink} from './api';
import queryString from 'query-string';

function FallbackPortal(props) {
    useEffect(() => {
        const params = queryString.parse(props.location.search);
        requestFallbackPortalLink(params.channelId, params.programId).then(r => window.location.replace(r));
    }, [props]);
    return <div></div>;
}

export default FallbackPortal;
