import {isDef} from '../utils';

class OfferHelper {
    static couponIsValid = coupon => {
        if (!isDef(coupon)) return false;

        if (isDef(coupon.notFound) && coupon.notFound === true) return false;

        return !(isDef(coupon.invalid) && coupon.invalid === true);
    };

    static offerHasValidCoupon = offer => {
        if (!isDef(offer.coupon)) return false;

        return this.couponIsValid(offer.coupon);
    };

    static offerCouponIsNotFoundOrInvalid = offer => {
        if (!isDef(offer.coupon)) return false;

        if (isDef(offer.coupon.notFound) && offer.coupon.notFound === true) return true;

        return !!(isDef(offer.coupon.invalid) && offer.coupon.invalid === true);
    };
}

export default OfferHelper;
