import React, {useEffect, useState} from 'react';
import './styles.scss';
import {requestCoupon, requestLink, requestPortal, sendBeacon} from './api';
import {Offers} from './components/offers';
import queryString from 'query-string';
import OfferHelper from './helpers/offerHelper';
import {isDef} from './utils';
import TagManager from 'react-gtm-module';

function App(props) {
    const [portal, setPortal] = useState(undefined);
    const [loading, setLoading] = useState(true);
    const [loadingOfferId, setLoadingOfferId] = useState(undefined);
    const [preview, setPreview] = useState(false);
    const [fallbackEmptyOffers, setFallbackEmptyOffers] = useState(false);

    //Google Tag Manager
    TagManager.initialize({
        gtmId: 'GTM-KH5RQTF',
    });

    useEffect(() => {
        const params = queryString.parse(props.location.search);
        setPreview(params.preview);
        if (isDef(params.jwt)) {
            //Gets the "real" portal-data
            requestPortal(params.jwt, params.lang, params.preview, params.fallback).then(
                function (data) {
                    // Storing params in state for use later on
                    data.jwt = params.jwt;
                    data.lang = params.lang;

                    // setting browser title
                    document.title = data.title;

                    // update state to show offers
                    setPortal(data);
                    setLoading(false);

                    if (params.fallback === 'true' && (!data.offers || data.offers.length === 0)) {
                        setFallbackEmptyOffers(true);
                    }
                },
                function () {
                    setPortal({error: true});
                    setLoading(false);
                }
            );
        } else if (isDef(params.adv) && isDef(params.order)) {
            requestLink(params.adv, params.order, isDef(params.lang) ? params.lang : null).then(link => {
                console.log(link);
                window.location.replace(link);
            });
        }
    }, [props]);

    const handleCouponChosen = args => {
        // Set state for loading offer id
        setLoadingOfferId(args.id);

        requestCoupon(args.id, portal.jwt, portal.lang, preview, args.share, args.channelId)
            .then(function (coupon) {
                console.log('coupon', coupon);

                if (OfferHelper.couponIsValid(coupon)) {
                    sendBeacon(coupon.url);
                }

                // For debugging issues, logging to console.
                if (coupon.notFound) {
                    console.warn('Offer Not Found', coupon);
                } else if (coupon.invalid) {
                    console.warn('Offer invalid', coupon);
                }

                // Update state based on response from server
                setPortal(prevState => {
                    prevState.offers.forEach(function (item) {
                        if (item.id === args.id) {
                            item.coupon = coupon;
                        }
                    });

                    return {...prevState, ...{}};
                });

                // Remove loading state from any offer
                setLoadingOfferId(0);
            })
            .catch(function (e) {
                // TODO
            });
    };

    if (loading) {
        return (
            <div>
                <h2>Loading...</h2>
            </div>
        );
    }


    if (fallbackEmptyOffers) {
        return (
            <div style={{all: 'revert'}}>
                <style>{'body { all: initial; text-align: center; } #poweredBy, #favicon { display: none; }'}</style>
                <div style={{maxWidth: '800px', margin: '50px auto', textAlign: 'left'}}>
                    <h1 style={{all: 'revert'}}>Invalid link</h1>
                    <div style={{borderTop: '1px solid #eee'}}></div>
                    <p>The link you are trying to access is broken or no longer available.</p>
                    <p>
                        <a href='#' onClick={() => window.history.back()}>
                            Go back
                        </a>
                    </p>
                </div>
            </div>
        );
    }

    if (portal.error) {
        return (
            <div>
                <h2>Could not load offers...</h2>
            </div>
        );
    }

    if (portal.expired) {
        return (
            <div>
                <h2>{portal.description}</h2>
            </div>
        );
    }

    return (
        <React.Fragment>
            <header>
                <img src={portal.logo} alt='' />
            </header>
            <main>
                <div>
                    <h1 style={{color: portal.color}}>{portal.title}</h1>
                    <p>{portal.description}</p>
                    <p>
                        <em>{portal.disclaimer}</em>
                    </p>
                    <div
                        style={{
                            fontSize: '1.6rem',
                            fontStyle: 'italic',
                            marginTop: '2rem',
                            color: '#bbb',
                        }}
                    >
                        {portal.sponsoredLinks}
                    </div>
                    <Offers
                        offers={portal.offers}
                        buttonText={portal.button}
                        onCouponChosen={handleCouponChosen}
                        loadingOfferId={loadingOfferId}
                        advertiserId={portal.advertiserId}
                    />
                </div>
            </main>
        </React.Fragment>
    );
}

export default App;
