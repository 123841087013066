import React, {useState} from 'react';

export const OfferShareContent = ({offer, onChosen}) => {

    const [activeTab, setActiveTab] = useState(offer.sent ? (isMobile ? 1 : 2) : null);
    const [message, setMessage] = useState(offer.shareMessageText + ' ' + offer.shareLink);

    const selectTab = (evt, tab) => {
        setActiveTab(tab);
    };

    const sendShareMessage = () => {
        if (activeTab === 1) {
            window.open('sms:?&body=' + encodeURIComponent(message), '_blank').focus();
        }
        if (activeTab === 2) {
            window.open('mailto:?subject=' + encodeURIComponent(offer.shareSubjectText) + '&body=' + encodeURIComponent(message), '_blank').focus();
        }
        if (activeTab === 3) {
            window.open('https://wa.me/?text=' + encodeURIComponent(message), '_blank').focus();
        }
        onChosen(offer);
    };

    const checkMessage = () => {
        const linkInclude = message.startsWith(offer.shareLink + ' ') || message.endsWith(' ' + offer.shareLink) || message.includes(' ' + offer.shareLink + ' ');
        if (!linkInclude) {
            setMessage(message + ' ' + offer.shareLink);
        }
    };

    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

    return (
        <div>
            <p>{offer.shareIntroductionText}</p>
            {activeTab != null &&
                <div className={'tabwrapper'} style={!isMobile ? {marginTop: 0} : {}}>
                    {isMobile &&
                        <div className='tab'>
                            <button className={'tablinks ' + (activeTab === 1 ? 'active' : '')} onClick={(event) => selectTab(event, 1)}>SMS</button>
                            <button className={'tablinks ' + (activeTab === 3 ? 'active' : '')} onClick={(event) => selectTab(event, 3)}>WhatsApp</button>
                            <button className={'tablinks ' + (activeTab === 2 ? 'active' : '')} onClick={(event) => selectTab(event, 2)}>Email</button>
                        </div>
                    }

                    <div className='tabcontent'>
                        <textarea value={message} onChange={(e) => setMessage(e.target.value)} onBlur={() => checkMessage()} rows='9' cols='20' />
                    </div>
                </div>
            }
            {activeTab === null &&
                <button onClick={() => setActiveTab(isMobile ? 1 : 2)} className={'shareButton'}>
                    <span className='content'>{offer.shareButtonText}</span>
                </button>
            }

            {activeTab &&
                <button onClick={() => sendShareMessage()} className={'shareButton'}>
                    <span className='content'>{isMobile ? offer.shareSendText : offer.shareSendEmailText}</span>
                </button>
            }

        </div>
    );
};
