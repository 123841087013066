import {isDef} from './utils';

export const hostname = window.location.hostname.trim().toLowerCase();

let apiOverride;
if (typeof URLSearchParams !== 'undefined') {
    var urlParams = new URLSearchParams(window.location.search);

    const adtractionVer = urlParams.get('adtraction_ver');

    if (isDef(adtractionVer) && ['true', 'olgadev'].includes(adtractionVer.trim().toLowerCase())) {
        apiOverride = 'https://api.adtraction.rocks';
        if (hostname.startsWith('stage.') && adtractionVer.trim().toLowerCase() === 'olgadev') {
            apiOverride = 'https://api.olga.dev';
        }
        console.log('api parameter override', apiOverride);
    }
}

const PROD_API_URL = 'https://api.adtraction.net';

export const API_URL = isDef(apiOverride)
    ? apiOverride
    : isDef(process) && isDef(process.env) && isDef(process.env.REACT_APP_API_URL)
        ? process.env.REACT_APP_API_URL
        : hostname === 'localhost' || hostname === '127.0.0.1'
            ? 'https://api.jonathan.dev'
            : hostname.startsWith('stage.')
                ? 'https://api.adtraction.rocks'
                : PROD_API_URL;

if (API_URL !== PROD_API_URL) {
    console.log('API_URL', API_URL);
}

export const IS_DEV = process.env.NODE_ENV === undefined || process.env.NODE_ENV === 'development';

if (IS_DEV === true) {
    console.log('IS_DEV', IS_DEV);
}
