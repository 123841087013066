import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import ErrorBoundary from './components/errorBoundary';
import App from './App';
import * as serviceWorker from './serviceWorker';
import {BrowserRouter, Route, Switch} from 'react-router-dom';
import OfferPreview from './OfferPreview';
import {isDef} from './utils';
import PortalLink from './PortalLink';
import AdvertiserPortal from './AdvertiserPortal';
import FallbackPortal from './FallbackPortal';

const Router = () => {
    const urlParams = new URLSearchParams(window.location.search);
    let previewOffer = urlParams.get('preview_offer') === 'true';
    let previewOfferShare = urlParams.get('preview_offer_share') === 'true';
    let link = isDef(urlParams.get('id')) && !isDef(urlParams.get('jwt'));
    let advertiserPortal = urlParams.get('advertiserPortal') === 'true';
    let offerSharePortal = isDef(urlParams.get('share')) && !isDef(urlParams.get('jwt'));
    let fallback = urlParams.get('fallback') === 'true' && !isDef(urlParams.get('jwt'));
    window.disableAdtIntercom = undefined;

    return (
        <BrowserRouter>
            <Switch>
                <Route
                    path='*'
                    component={
                        advertiserPortal || offerSharePortal
                            ? AdvertiserPortal
                            : previewOffer || previewOfferShare
                                ? OfferPreview
                                : link === true
                                    ? PortalLink
                                    : fallback
                                        ? FallbackPortal
                                        : App
                    }
                />
            </Switch>
        </BrowserRouter>
    );
};

ReactDOM.render(
    <React.StrictMode>
        <ErrorBoundary>
            <Router />
        </ErrorBoundary>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
