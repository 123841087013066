import React from 'react';
import {Offer} from './offer';
import OfferHelper from '../helpers/offerHelper';
import {isDef} from '../utils';

export const Offers = ({
                           offers,
                           buttonText,
                           onCouponChosen,
                           loadingOfferId,
                           advertiserPortal,
                           advertiserId
                       }) => {
    const offerShareSent = offers && offers.filter(o => OfferHelper.offerHasValidCoupon(o) && o.id === advertiserId).length > 0;
    let hasTakenOffer = offers && offers.filter(o => OfferHelper.offerHasValidCoupon(o)).length === (3 + (offerShareSent ? 1 : 0));

    const ulClass = hasTakenOffer ? 'activated' : '';

    let isLoading = function (item) {
        if (!isDef(loadingOfferId)) return false;
        return item.id === loadingOfferId;
    };

    return (
        <ul className={ulClass}>
            {offers && offers.map(item => (
                <Offer
                    key={item.id}
                    offer={item}
                    buttonText={buttonText}
                    disabled={hasTakenOffer}
                    isLoading={isLoading(item)}
                    onCouponChosen={onCouponChosen}
                    advertiserPortal={advertiserPortal}
                />
            ))}
        </ul>
    );
};
