import React, {useState} from 'react';
import OfferHelper from './../helpers/offerHelper';
import {OfferShareContent} from './offershare';

export const Offer = props => {
    const [copyButtonText, setCopyButtonText] = useState('');

    const [offerShare, setOfferShare] = useState(props.offer.share && !props.offer.sent);
    const isTaken = OfferHelper.offerHasValidCoupon(props.offer);
    const isNotFoundOrInvalid = OfferHelper.offerCouponIsNotFoundOrInvalid(props.offer);

    const liClassName = isTaken ? 'taken' : '';

    const copyToClipboard = args => {
        // Creates a hidden textarea to be able to copy the code
        const el = document.createElement('textarea');
        el.value = args;
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);

        setCopyButtonText(props.offer.coupon.copied);
    };

    let btnCss = props.isLoading === true ? 'loading' : '';

    const goToLink = link => {
        window.open(link, '_blank').focus();
    };

    return (
        <li className={liClassName} style={props.advertiserPortal === true || offerShare ? {opacity: 1} : {}}>
            <div>
                <img src={props.offer.image} alt='' />
                <div
                    role='button'
                    tabIndex='0'
                    onKeyPress={() => goToLink(props.offer.programLink)}
                    onClick={() => goToLink(props.offer.programLink)}
                    style={{cursor: 'pointer'}}
                >
                    <img src={props.offer.logo} alt='' />
                </div>
            </div>
            <div>
                <strong style={{backgroundColor: props.offer.color}}>
                    <span>{props.offer.title}</span>
                    <span />
                    <span />
                </strong>

                {offerShare &&
                    <OfferShareContent offer={props.offer} onChosen={(args) => {
                        props.onCouponChosen(args);
                        setOfferShare(false);
                    }} />
                }

                {!offerShare && isTaken === false && isNotFoundOrInvalid === false && (
                    <div>
                        <p>{props.offer.description}</p>
                        <button
                            onClick={() => props.onCouponChosen(props.offer)}
                            disabled={props.advertiserPortal === true ? false : props.disabled}
                            className={btnCss}
                        >
                            <span className='spinner'>
                                <span className='bounce1' />
                                <span className='bounce2' />
                                <span className='bounce3' />
                            </span>
                            <span className='content'>{props.buttonText}</span>
                        </button>
                    </div>
                )}

                {!offerShare && isTaken === false && isNotFoundOrInvalid === true && (
                    <div>
                        <p>{props.offer.description}</p>
                        <button disabled={true}>
                            <span className='spinner'>
                                <span className='bounce1' />
                                <span className='bounce2' />
                                <span className='bounce3' />
                            </span>
                            <span className='content'>{props.offer.coupon.button}</span>
                        </button>
                    </div>
                )}

                {!offerShare && isTaken && (
                    <div>
                        <p style={{fontSize: '1.2rem', textAlign: 'left'}}>{props.offer.description}</p>
                        {props.offer.coupon.codeActive === true && <mark>{props.offer.coupon.code}</mark>}
                        {props.offer.coupon.codeActive === true && (
                            <button onClick={() => copyToClipboard(props.offer.coupon.code)}>
                                {copyButtonText || props.offer.coupon.button}
                            </button>
                        )}
                        {props.offer.coupon.instruction && <p>{props.offer.coupon.instruction}</p>}
                        {props.offer.coupon.codeActive === false && <p>{props.offer.coupon.urlFollowText}</p>}
                        <a href={props.offer.coupon.url} rel='noreferrer' target='_blank'>
                            <span>{props.offer.coupon.urlText}</span>
                            <svg
                                width='9px'
                                height='14px'
                                viewBox='0 0 9 14'
                                version='1.1'
                                xmlns='http://www.w3.org/2000/svg'
                            >
                                <g id='Page-1' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
                                    <g
                                        id='1.B-Erbjudanden-valt'
                                        transform='translate(-1099.000000, -636.000000)'
                                        fill='#404040'
                                    >
                                        <g
                                            id='Icons/Arrow-down-medium'
                                            transform='translate(1103.375000, 643.125000) rotate(-360.000000) translate(-1103.375000, -643.125000) translate(1098.375000, 635.625000)'
                                        >
                                            <path
                                                d='M0.796296296,3.375 L8.98148148,3.375 C9.53376623,3.375 9.98148148,3.82271525 9.98148148,4.375 C9.98148148,4.92728475 9.53376623,5.375 8.98148148,5.375 L0.796296296,5.375 C0.244011546,5.375 -0.203703704,4.92728475 -0.203703704,4.375 C-0.203703704,3.82271525 0.244011546,3.375 0.796296296,3.375 Z'
                                                id='Rectangle'
                                                transform='translate(4.888889, 4.375000) rotate(-315.000000) translate(-4.888889, -4.375000) '
                                            />
                                            <path
                                                d='M0.796296296,9.16311354 L8.98148148,9.16311354 C9.53376623,9.16311354 9.98148148,9.61082879 9.98148148,10.1631135 C9.98148148,10.7153983 9.53376623,11.1631135 8.98148148,11.1631135 L0.796296296,11.1631135 C0.244011546,11.1631135 -0.203703704,10.7153983 -0.203703704,10.1631135 C-0.203703704,9.61082879 0.244011546,9.16311354 0.796296296,9.16311354 Z'
                                                id='Rectangle'
                                                transform='translate(4.888889, 10.163114) rotate(-225.000000) translate(-4.888889, -10.163114) '
                                            />
                                        </g>
                                    </g>
                                </g>
                            </svg>
                        </a>
                        <p id={'contact-support'}>
                            {props.offer.offerNotWorkingText + ' '}
                            <a
                                href={'intercom'}
                                onClick={e => {
                                    e.preventDefault();
                                    window.Intercom('show');
                                }}
                            >
                                {props.offer.contactSupportText}
                            </a>
                        </p>
                    </div>
                )}
            </div>
        </li>
    );
};
