import React, {useEffect, useState} from 'react';
import './styles.scss';
import {requestAdvertiserPortal, requestAdvertiserPortalCoupon, requestOfferSharePortal, requestPortalLink, sendBeacon} from './api';
import {Offers} from './components/offers';
import queryString from 'query-string';
import OfferHelper from './helpers/offerHelper';
import {isDef} from './utils';

function AdvertiserPortal(props) {
    const [portal, setPortal] = useState(undefined);
    const [newChannelId, setNewChannelId] = useState(null);
    const [loading, setLoading] = useState(true);
    const [loadingOfferId, setLoadingOfferId] = useState(undefined);
    const [offerShare, setOfferShare] = useState(false);

    useEffect(() => {
        window.disableAdtIntercom = true;
        const params = queryString.parse(props.location.search);
        const share = !isDef(params.id) && isDef(params.share);
        //Gets the "real" portal-data
        const portalRequest = share ? requestOfferSharePortal(params.share) : requestAdvertiserPortal(params.id, params.channelId);
        setOfferShare(share);
        setNewChannelId(params.channelId);
        portalRequest.then(
            function(data) {
                // setting browser title
                document.title = data.title;

                if (!share) {
                    data.advertiserId = params.id;
                }

                // update state to show offers
                setPortal(data);
                setLoading(false);
            },
            function() {
                setPortal({error: true});
                setLoading(false);
            }
        );
    }, [props]);

    const handleCouponChosen = args => {
        // Set state for loading offer id
        setLoadingOfferId(args.id);

        requestAdvertiserPortalCoupon(args.id, portal.advertiserId, portal.lang, false, offerShare, newChannelId)
            .then(function (coupon) {
                console.log('coupon', coupon);

                if (OfferHelper.couponIsValid(coupon)) {
                    sendBeacon(coupon.url);
                }

                // For debugging issues, logging to console.
                if (coupon.notFound) {
                    console.warn('Offer Not Found', coupon);
                } else if (coupon.invalid) {
                    console.warn('Offer invalid', coupon);
                }

                // Update state based on response from server
                setPortal(prevState => {
                    prevState.offers.forEach(function (item) {
                        if (item.id === args.id) {
                            item.coupon = coupon;
                        }
                    });

                    return {...prevState, ...{}};
                });

                // Remove loading state from any offer
                setLoadingOfferId(0);
            })
            .catch(function (e) {
                // TODO
            });
    };

    if (loading) {
        return (
            <div className={'advertiser-portal'}>
                <h2>Loading...</h2>
            </div>
        );
    }

    if (portal.error) {
        return (
            <div className={'advertiser-portal'}>
                <h2>Could not load offers...</h2>
            </div>
        );
    }

    if (portal.expired) {
        return (
            <div className={'advertiser-portal'}>
                <h2>{portal.description}</h2>
            </div>
        );
    }

    return (
        <React.Fragment>
            {offerShare &&
                <header>
                    <img src={portal.logo} alt='' />
                </header>
            }
            <main className={'advertiser-portal'}>
                <div>
                    <Offers
                        offers={portal.offers}
                        buttonText={portal.button}
                        onCouponChosen={handleCouponChosen}
                        loadingOfferId={loadingOfferId}
                        advertiserPortal={true}
                        advertiserId={portal.advertiserId}
                    />
                </div>
            </main>
        </React.Fragment>
    );
}

export default AdvertiserPortal;
