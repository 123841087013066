import React, {useCallback, useEffect, useState} from 'react';
import './styles.scss';
import {requestPortalLink} from './api';
import queryString from 'query-string';
import {isDef} from './utils';

function PortalLink(props) {
    const [data, setData] = useState(undefined);
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(true);

    const copyToClipboard = () => {
        const el = document.getElementById('portalLinkInput');
        el.select();
        document.execCommand('copy');
    };

    const generateNewLink = useCallback(() => {
        setLoading(true);
        const params = queryString.parse(props.location.search);
        if (isDef(params.id)) {
            requestPortalLink(params.id, params.lang, params.preview).then(
                function (data) {
                    setData(data);
                    if (!isDef(data.shortUrl)) {
                        setError(true);
                    }
                    document.title = data.titleText;
                    setLoading(false);
                },
                function (e) {
                    console.error(e);
                    setError(true);
                    setLoading(false);
                }
            );
        }
    }, [props]);

    useEffect(() => {
        generateNewLink();
    }, [generateNewLink, props]);

    if (error) {
        return (
            <div>
                <h2>Could not load portal link...</h2>
            </div>
        );
    }

    return (
        <React.Fragment>
            <header>{data && <img src={data.logo} alt='' />}</header>
            <main>
                {data && (
                    <div>
                        <h1 style={{color: data.color}}>{data.titleText}</h1>
                        <p>{data.validText}</p>
                        <div>
                            <input
                                id={'portalLinkInput'}
                                style={{width: '40%', display: 'inline'}}
                                type='text'
                                value={data.shortUrl}
                                readOnly={true}
                            />
                            <button className={'portalLinkBtn'} onClick={() => copyToClipboard()}>
                                {data.copyText}
                            </button>
                        </div>
                        <button
                            id={'generatePortalLinkBtn'}
                            className={'portalLinkBtn'}
                            onClick={() => generateNewLink()}
                        >
                            {data.generateText}
                        </button>
                    </div>
                )}
                {loading && (
                    <div>
                        <h2>Loading...</h2>
                    </div>
                )}
            </main>
        </React.Fragment>
    );
}

export default PortalLink;
