import React, {useEffect, useState} from 'react';
import './styles.scss';
import {requestCoupon, requestOffer, sendBeacon} from './api';
import {Offers} from './components/offers';
import queryString from 'query-string';
import OfferHelper from './helpers/offerHelper';
import {isDef} from './utils';

function OfferPreview(props) {
    const [offer, setOffer] = useState(undefined);
    const [loading, setLoading] = useState(true);
    const [loadingOfferId, setLoadingOfferId] = useState(undefined);

    useEffect(() => {
        const params = queryString.parse(props.location.search);

        if (isDef(params.jwt)) {
            requestOffer(params.jwt, params.preview_offer_share === 'true', params.channelId).then(
                function (data) {
                    // setting browser title
                    document.title = data.title;

                    data.jwt = params.jwt;
                    data.lang = params.lang;

                    // update state to show offers
                    setOffer(data);

                    setLoading(false);
                },
                function () {
                    setOffer({error: true});
                    setLoading(false);
                }
            );
        }
    }, [props]);

    const handleCouponChosen = args => {
        // Set state for loading offer id
        setLoadingOfferId(args.id);

        requestCoupon(args.id, offer.jwt, offer.lang, true, args.share, args.channelId)
            .then(function (coupon) {
                console.log('coupon', coupon);

                if (OfferHelper.couponIsValid(coupon)) {
                    sendBeacon(coupon.url);
                }

                // For debugging issues, logging to console.
                if (coupon.notFound) {
                    console.warn('Offer Not Found', coupon);
                } else if (coupon.invalid) {
                    console.warn('Offer invalid', coupon);
                }

                // Update state based on response from server
                setOffer(prevState => {
                    prevState.offers.forEach(function (item) {
                        if (item.id === args.id) {
                            item.coupon = coupon;
                        }
                    });
                    return {...prevState, ...{}};
                });

                // Remove loading state from any offer
                setLoadingOfferId(0);
            })
            .catch(function (e) {
                console.error(e);
            });
    };

    if (loading) {
        return (
            <div>
                <h2>Loading...</h2>
            </div>
        );
    }

    if (offer.error) {
        return (
            <div>
                <h2>Could not load offer...</h2>
            </div>
        );
    }

    return (
        <React.Fragment>
            <header></header>
            <main>
                <div>
                    <Offers
                        offers={offer.offers}
                        buttonText={offer.button}
                        onCouponChosen={handleCouponChosen}
                        loadingOfferId={loadingOfferId}
                        advertiserId={offer.advertiserId}
                    />
                </div>
            </main>
        </React.Fragment>
    );
}

export default OfferPreview;
