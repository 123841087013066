/**
 * Example: await sleep(2000);
 *
 * @param ms
 * @returns {Promise<unknown>}
 */
export const sleep = async ms => {
    console.log('Sleeping for ' + ms + ' ms');
    return new Promise(resolve => setTimeout(resolve, ms));
};

export const isDef = v => {
    return v !== undefined && v !== null;
};

export const stringify = object => {
    return JSON.stringify(object, null, 2);
};
